<template>
  <div>
    <v-container class="container--fluid">
      <v-row dense>
        <v-col cols="12">
          <v-card>
            <v-col cols="12">
              <v-row>
                <v-col cols="2" sm="2" md="2" class="pr-3">
                  <v-select
                    v-model="analiysis_typeModel"
                    :items="analiysis_type"
                    label="Raport Tipi"
                  />
                </v-col>

                <v-col cols="12" sm="2" md="2" class="pr-3">
                  <v-select v-model="reportTypeModel" :items="reportType" />
                </v-col>

                <v-col
                  v-if="analiysis_typeModel == 'generalStats'"
                  cols="3"
                  sm="2"
                  md="2"
                  class="pr-3"
                >
                  <v-select
                    v-model="santralModel"
                    :items="santralDataList"
                    label="Santral"
                    multiple
                  >
                    <template v-slot:selection="{ item, index }">
                      <span
                        v-if="index === 0"
                        style="max-width: 120px"
                        class="d-inline-block text-truncate"
                        >{{ item }}</span
                      >
                      <span v-if="index === 1" class="grey--text text-caption"
                        >(+{{ santralModel.length - 1 }})</span
                      >
                    </template>
                  </v-select>
                </v-col>

                <v-col v-else cols="3" sm="2" md="2" class="pr-3">
                  <v-select
                    v-model="santralModel_string"
                    :items="santralDataList"
                    label="Santral"
                  />
                </v-col>

                <v-spacer />

                <v-col cols="1" sm="1" md="1" class="pr-3">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        fab
                        dark
                        small
                        color="cyan"
                        v-on="on"
                        @click="exportDocument"
                      >
                        <v-icon>mdi-download</v-icon>
                      </v-btn>
                    </template>
                    <span>Export to Excel</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-col>
          </v-card>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col v-if="analiysis_typeModel == 'generalStats'" cols="12">
          <v-card>
            <v-col cols="12" class="pl-0 pr-0 pb-1">
              <v-row>
                <v-spacer />

                <v-col
                  v-if="
                    reportTypeModel === 'Monthly' || reportTypeModel === 'Daily'
                  "
                  cols="3"
                  sm="1"
                  md="1"
                  class="pr-3"
                >
                  <v-select v-model="yearModel" :items="yearList" label="Yıl" />
                </v-col>

                <v-col
                  v-if="reportTypeModel === 'Daily'"
                  cols="3"
                  sm="1"
                  md="1"
                  class="pr-3"
                >
                  <v-select
                    v-model="monthModel"
                    :items="monthList"
                    label="Ay"
                  />
                </v-col>

                <v-col
                  v-if="
                    reportTypeModel == 'Hourly' || reportTypeModel == 'Minute'
                  "
                  cols="3"
                  sm="2"
                  md="2"
                  class="pr-3"
                >
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="startDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="startDate"
                        label="Tarih"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker v-model="startDate" no-title scrollable>
                      <v-spacer />
                      <v-btn text color="primary" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(startDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="3" sm="3" md="3" class="pr-3 mt-4">
                  <v-select
                    v-model="measuresModel"
                    :items="measures"
                    label="Ölçü"
                    dense
                  />
                </v-col>

                <v-col cols="1" sm="1" md="1" class="pr-3">
                  <v-select
                    v-model="chartTypeModel"
                    :items="chartType"
                    label="Grafik"
                  />
                </v-col>

                <v-col cols="3" sm="1" md="1" class="pr-3 pt-3">
                  <v-btn color="primary" @click="getData"> Filtrele </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-card>
        </v-col>

        <v-col v-if="analiysis_typeModel == 'INVERTER'" cols="12">
          <v-card>
            <v-col cols="12" class="pl-0 pr-0 pb-1">
              <v-row>
                <v-spacer />

                <v-col
                  v-if="
                    reportTypeModel === 'Monthly' || reportTypeModel === 'Daily'
                  "
                  cols="3"
                  sm="1"
                  md="1"
                  class="pr-3"
                >
                  <v-select v-model="yearModel" :items="yearList" label="Yıl" />
                </v-col>

                <v-col
                  v-if="reportTypeModel === 'Daily'"
                  cols="3"
                  sm="1"
                  md="1"
                  class="pr-3"
                >
                  <v-select
                    v-model="monthModel"
                    :items="monthList"
                    label="Ay"
                  />
                </v-col>

                <v-col
                  v-if="
                    reportTypeModel == 'Hourly' || reportTypeModel == 'Minute'
                  "
                  cols="3"
                  sm="2"
                  md="2"
                  class="pr-3"
                >
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="startDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="startDate"
                        label="Tarih"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker v-model="startDate" no-title scrollable>
                      <v-spacer />
                      <v-btn text color="primary" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(startDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="3" sm="2" md="2" class="pr-3">
                  <v-select
                    v-model="inverterModel"
                    :items="inverterList[santralModel_string]"
                    label="İnverter"
                    multiple
                  >
                    <template v-slot:selection="{ item, index }">
                      <span
                        v-if="index === 0"
                        style="max-width: 120px"
                        class="d-inline-block text-truncate"
                        >{{ item.text }}</span
                      >
                      <span v-if="index === 1" class="grey--text text-caption"
                        >(+{{ inverterModel.length - 1 }})</span
                      >
                    </template>
                  </v-select>
                </v-col>

                <v-col cols="2" sm="2" md="2" class="pr-3 mt-4">
                  <v-select
                    v-model="measuresModel"
                    :items="measures"
                    label="Ölçü"
                    dense
                  />
                </v-col>

                <v-col cols="1" sm="1" md="1" class="pr-3">
                  <v-select
                    v-model="chartTypeModel"
                    :items="chartType"
                    label="Grafik"
                  />
                </v-col>

                <v-col cols="3" sm="1" md="1" class="pr-3 pt-3">
                  <v-btn color="primary" @click="getData"> Filtrele </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-card>
        </v-col>

        <v-col v-if="analiysis_typeModel == 'STRINGBOX'" cols="12">
          <v-card>
            <v-col cols="12" class="pl-0 pr-0 pb-1">
              <v-row>
                <v-spacer />

                <v-col
                  v-if="
                    reportTypeModel === 'Monthly' || reportTypeModel === 'Daily'
                  "
                  cols="3"
                  sm="1"
                  md="1"
                  class="pr-3"
                >
                  <v-select v-model="yearModel" :items="yearList" label="Yıl" />
                </v-col>

                <v-col
                  v-if="reportTypeModel === 'Daily'"
                  cols="3"
                  sm="1"
                  md="1"
                  class="pr-3"
                >
                  <v-select
                    v-model="monthModel"
                    :items="monthList"
                    label="Ay"
                  />
                </v-col>

                <v-col
                  v-if="
                    reportTypeModel == 'Hourly' || reportTypeModel == 'Minute'
                  "
                  cols="3"
                  sm="2"
                  md="2"
                  class="pr-3"
                >
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="startDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="startDate"
                        label="Tarih"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker v-model="startDate" no-title scrollable>
                      <v-spacer />
                      <v-btn text color="primary" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(startDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="3" sm="2" md="2" class="pr-3">
                  <v-select
                    v-model="combinerBox"
                    :items="combinerboxList[santralModel_string]"
                    label="String"
                    multiple
                  >
                    <template v-slot:selection="{ item, index }">
                      <span
                        v-if="index === 0"
                        style="max-width: 120px"
                        class="d-inline-block text-truncate"
                        >{{ item.text }}</span
                      >
                      <span v-if="index === 1" class="grey--text text-caption"
                        >(+{{ combinerBox.length - 1 }})</span
                      >
                    </template>
                  </v-select>
                </v-col>

                <v-col cols="2" sm="2" md="2" class="pr-3 mt-4">
                  <v-select
                    v-model="measuresModel"
                    :items="measures"
                    label="Ölçü"
                    dense
                  />
                </v-col>

                <v-col cols="1" sm="1" md="1" class="pr-3">
                  <v-select
                    v-model="chartTypeModel"
                    :items="chartType"
                    label="Grafik"
                  />
                </v-col>

                <v-col cols="3" sm="1" md="1" class="pr-3 pt-3">
                  <v-btn color="primary" @click="getData"> Filtrele </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-card>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12">
          <v-card>
            <div
              id="general-santral-analiz-daily"
              style="height: 300px; min-width: 900px; width: 100%; padding: 1px"
            />
          </v-card>
        </v-col>
      </v-row>

      <v-col cols="12" class=" mt-2 ml-1 mr-1 pl-0 pr-0">
        <v-card>
          <v-data-table
            :id="santralDataTable"
            dense
            :items-per-page="itemsPerPage"
            :headers="headers"
            :items="desserts"
            class="elevation-1"
          />
        </v-card>
      </v-col>
    </v-container>
    <ExportDocument :export-document-open="exportDocumentOpen" />
  </div>
</template>
<script>
import * as echarts from "echarts";
import * as SantralAnalysis from "@/api/Analysis/SantralAnalysis";
import moment from "moment";
import ExportDocument from "@/views/components/Shared/ExportDocument.vue";
import i18n from "@/locale";
import helper from "@/api/helper";

import {
  getSantralMinute,
  getSantralHourly,
  getSantralDaily,
  getSantralMonthly,
  getSantralYearly,
} from "@/api/Request/santral";

import {
  stringboxMinute,
  stringboxHourly,
  stringboxDaily,
  stringboxMonthly,
  stringboxYearly,
} from "@/api/Request/stringbox";

import {
  inverterHourly,
  inverterMinute,
  inverterDaily,
  inverterMonthly,
  inverterYearly,
} from "@/api/Request/inverter";

export default {
  name: "GeneralStatsHour",
  components: {
    ExportDocument,
  },
  data: () => ({
    chartData: {},
    startDate: moment().format("YYYY-MM-DD"),
    finishDate: moment().format("YYYY-MM-DD"),
    menu: false,
    modal: false,
    menu2: false,
    measuresModel: "DailyEnergy",
    itemsPerPage: 500,
    // eslint-disable-next-line radix
    yearModel: parseInt(moment().format("YYYY")),
    // eslint-disable-next-line radix
    monthModel: parseInt(moment().format("MM")),
    measures: [],
    chartType: ["line", "bar"],
    chartTypeModel: "line",
    componentKey: 1,
    headers: [],
    desserts: [],
    reportTypeModel: "Minute",
    reportType: [],
    weekListModel: 10,
    weekList: [],
    exportDocumentOpen: {
      open: false,
      tableID: null,
    },
    santralDataTable: "santral_Analysis_table",
    chartText: "",
    newData: "true",
    santralModel: [],
    analiysis_type: [
      { text: "Genel İstatistik", value: "generalStats" },
      { text: "İnverter", value: "INVERTER" },
      { text: "Stringbox", value: "STRINGBOX" },
    ],
    analiysis_typeModel: "generalStats",
    combinerBox: [],
    santralModel_string: "",
    inverterModel: [],
    santralInfo: {},
    stepTime: 10,
    show: true,
  }),
  computed: {
    inverterList() {
      const { santralInverterData } = this.$store.getters;
      if (
        !helper.is_Empty(santralInverterData) &&
        !helper.is_Empty(this.santralModel_string)
      ) {
        const inverterList = {};

        Object.keys(santralInverterData).forEach((inv) => {
          inverterList[inv] = [];
          Object.keys(santralInverterData[inv]).forEach((k) => {
            inverterList[inv].push({
              text: santralInverterData[inv][k].INVERTERNAME,
              value: santralInverterData[inv][k].INVERTER,
            });
          });
        });

        return inverterList;
      }
      return [];
    },
    santralDataList() {
      const { santralDataList } = this.$store.getters;
      if (santralDataList === undefined) return;
      // eslint-disable-next-line prefer-destructuring, vue/no-side-effects-in-computed-properties
      this.santralModelString = santralDataList[0];
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.santralModel = santralDataList;
      // eslint-disable-next-line consistent-return
      return santralDataList;
    },

    yearList() {
      return this.$store.getters.clientYearList;
    },
    monthList() {
      return this.$store.getters.month;
    },
    combinerboxList() {
      const { santralStringboxData } = this.$store.getters;
      if (
        !helper.is_Empty(santralStringboxData) &&
        !helper.is_Empty(this.santralModelString)
      ) {
        const stringboxList = {};

        Object.keys(santralStringboxData).forEach((inv) => {
          stringboxList[inv] = [];
          Object.keys(santralStringboxData[inv]).forEach((k) => {
            stringboxList[inv].push({
              text: santralStringboxData[inv][k].STRINGBOXNAME,
              value: santralStringboxData[inv][k].STRINGBOX,
            });
          });
        });

        return stringboxList;
      }
      return [];
    },
  },
  watch: {
    reportTypeModel() {
      this.setData();
    },
    analiysis_typeModel() {
      this.reportTypeModel = "Minute";
    },
    combinerBox() {
      const { stringboxMeasureList } = this.$store.getters;
      // eslint-disable-next-line prefer-destructuring
      this.measuresModel = stringboxMeasureList[0];
      this.measures = stringboxMeasureList;
    },

    inverterModel() {
      const { inverterMeasureList } = this.$store.getters;
      // eslint-disable-next-line prefer-destructuring
      this.measuresModel = inverterMeasureList[0];
      this.measures = inverterMeasureList;
    },
  },

  created() {},
  mounted() {
    this.measures = [
      {
        text: i18n.t("PerformanceRatio"),
        value: "PerformanceRatio",
        unit: "%",
      },
      {
        text: i18n.t("TheoreticalPower"),
        value: "TheoreticalPower",
        unit: "kW",
      },
      {
        text: i18n.t("IstantaneousPower"),
        value: "IstantaneousPower",
        unit: "kW",
      },
      {
        text: i18n.t("DailyEnergy"),
        value: "DailyEnergy",
        unit: "kW",
      },
    ];

    this.reportType = [
      { text: "Dakika", value: "Minute" },
      { text: "Saatlik", value: "Hourly" },
      { text: "Günlük", value: "Daily" },
      { text: "Aylık", value: "Monthly" },
      { text: "Yıllık", value: "Yearly" },
    ];

    // eslint-disable-next-line no-use-before-define
    const myVar1 = setInterval(myTimer1, 1000);

    const self = this;
    function myTimer1() {
      // santralRawData
      const { santral } = self.$store.getters.selectedSantralModel;
      const {
        santralStringboxData,
        santralInverterData,
        santralSensorData,
        santralDataList,
      } = self.$store.getters;

      if (
        santral !== undefined &&
        Object.keys(santralStringboxData).length > 0
      ) {
        self.santralModel = [santral];

        const santralInfo = {};
        Object.keys(santralDataList).forEach((i) => {
          if (santralInfo[santralDataList[i]] === undefined) {
            santralInfo[santralDataList[i]] = {
              INVERTER: [],
              STRINGBOX: [],
              SENSOR: [],
            };
          }
          if (santralInverterData[santralDataList[i]]) {
            santralInfo[santralDataList[i]].INVERTER =
              santralInverterData[santralDataList[i]];
          }
          if (santralInverterData[santralDataList[i]]) {
            santralInfo[santralDataList[i]].STRINGBOX =
              santralStringboxData[santralDataList[i]];
          }
          if (santralInverterData[santralDataList[i]]) {
            santralInfo[santralDataList[i]].SENSOR =
              santralSensorData[santralDataList[i]];
          }
        });

        self.santralInfo = santralInfo;
        self.stepTime = 5;

        clearInterval(myVar1);
        self.getData();
      }
    }
  },
  methods: {
    setStatsMeasure() {
      if (["Minute", "Hourly"].indexOf(this.reportTypeModel) > -1) {
        this.measures = [
          {
            text: i18n.t("PerformanceRatio"),
            value: "PerformanceRatio",
            unit: "%",
          },
          {
            text: i18n.t("TheoreticalPower"),
            value: "TheoreticalPower",
            unit: "kW",
          },
          {
            text: i18n.t("IstantaneousPower"),
            value: "IstantaneousPower",
            unit: "kW",
          },
          {
            text: i18n.t("DailyEnergy"),
            value: "DailyEnergy",
            unit: "kW",
          },
          {
            text: i18n.t("EquivalentTrees"),
            value: "EquivalentTrees",
            unit: "",
          },
          { text: i18n.t("CO2Avoided"), value: "CO2Avoided", unit: "" },
          { text: i18n.t("BarrelsofOil"), value: "BarrelsofOil", unit: "" },
        ];
      } else {
        this.measures = [
          {
            text: i18n.t("PerformanceRatio"),
            value: "PerformanceRatio",
            unit: "%",
          },
          {
            text: i18n.t("DailyEnergy"),
            value: "DailyEnergy",
            unit: "kW",
          },
          {
            text: i18n.t("TheoreticalPower"),
            value: "TheoreticalPower",
            unit: "kW",
          },
          {
            text: i18n.t("EquivalentTrees"),
            value: "EquivalentTrees",
            unit: "",
          },
          { text: i18n.t("CO2Avoided"), value: "CO2Avoided", unit: "" },
          { text: i18n.t("BarrelsofOil"), value: "BarrelsofOil", unit: "" },
        ];
      }
    },
    exportDocument() {
      this.exportDocumentOpen.open = true;
      this.exportDocumentOpen.tableID = this.santralDataTable;
    },
    setData() {
      if (this.analiysis_typeModel === "generalStats") {
        this.setStatsMeasure();
      }

      if (this.analiysis_typeModel === "INVERTER") {
        this.setInverter();
      }

      if (this.analiysis_typeModel === "STRINGBOX") {
        this.setStringMeasure();
      }

      this.getData();
    },
    getData() {
      this.show = false;
      let headers = [{ text: "Tarih", value: "date" }];
      if (this.analiysis_typeModel === "generalStats") {
        this.santralModel.forEach((santral) => {
          headers.push({
            text: santral,
            value: santral,
          });
        });
        this.headers = headers;
        this.getSantralDataChart(this.santralModel, "santral");
      }

      if (this.analiysis_typeModel === "INVERTER") {
        headers = [{ text: "Tarih", value: "date" }];
        const inverterList = this.inverterList[this.santralModel_string];
        this.headers = headers.concat(inverterList);
        this.getSantralDataChart(this.inverterModel, "INVERTER");
      }

      if (this.analiysis_typeModel === "STRINGBOX") {
        headers = [{ text: "Tarih", value: "date" }];
        const combinerBox = this.combinerboxList[this.santralModel_string];
        this.headers = headers.concat(combinerBox);
        this.getSantralDataChart(this.combinerBox, "STRINGBOX");
      }
    },
    /// / genel istatistik start
    getSantralDataChart(deviceList, deviceModelType) {
      const { measuresModel } = this;
      const { measures } = this;
      this.chartText = "";
      let measureList;
      measures.forEach((measure) => {
        if (measuresModel === measure.value) {
          measureList = measure;
        }
      });

      const formdata = this.selectedStatsParams();
      this.chartData = [];
      const self = this;
      let temp;

      let tempData;
      if (this.reportTypeModel === "Minute") {
        if (this.analiysis_typeModel === "generalStats") {
          temp = getSantralMinute(formdata);
        }

        if (this.analiysis_typeModel === "INVERTER") {
          temp = inverterMinute(formdata);
        }

        if (this.analiysis_typeModel === "STRINGBOX") {
          temp = stringboxMinute(formdata);
        }

        temp.then((_rawData) => {
          if (_rawData.data.success === "true") {
            tempData = SantralAnalysis.default.setDeviceMinuteData(
              _rawData.data,
              measureList,
              self.chartText,
              self.chartTypeModel,
              deviceModelType,
              deviceList,
              self.stepTime
            );

            this.setOpt(tempData);
          }
        });
      }

      if (this.reportTypeModel === "Hourly") {
        if (this.analiysis_typeModel === "generalStats") {
          temp = getSantralHourly(formdata);
        }

        if (this.analiysis_typeModel === "INVERTER") {
          temp = inverterHourly(formdata);
        }

        if (this.analiysis_typeModel === "STRINGBOX") {
          temp = stringboxHourly(formdata);
        }

        temp.then((_rawData) => {
          tempData = SantralAnalysis.default.set_device_hourly_data(
            _rawData.data,
            measureList,
            self.chartText,
            self.chartTypeModel,
            deviceModelType,
            deviceList
          );
          this.setOpt(tempData);
        });
      }
      if (this.reportTypeModel === "Daily") {
        let month;
        const m = parseInt(formdata.condiniton.month);
        m < 10 ? (month = `0${m}`) : (month = `${m}`);
        const y = formdata.condiniton.year;
        const startDate = `${y}-${month}-01`;

        if (this.analiysis_typeModel === "generalStats") {
          temp = getSantralDaily(formdata);
        }

        if (this.analiysis_typeModel === "INVERTER") {
          temp = inverterDaily(formdata);
        }

        if (this.analiysis_typeModel === "STRINGBOX") {
          temp = stringboxDaily(formdata);
        }

        temp.then((_rawData) => {
          tempData = SantralAnalysis.default.set_device_daily_data(
            _rawData.data,
            measureList,
            self.chartText,
            self.chartTypeModel,
            self.reportTypeModel,
            deviceModelType,
            deviceList,
            startDate
          );
          this.setOpt(tempData);
        });
      }
      if (this.reportTypeModel === "Monthly") {
        const dateList = formdata.condiniton.date;

        if (this.analiysis_typeModel === "generalStats") {
          temp = getSantralMonthly(formdata);
        }

        if (this.analiysis_typeModel === "INVERTER") {
          temp = inverterMonthly(formdata);
        }

        if (this.analiysis_typeModel === "STRINGBOX") {
          temp = stringboxMonthly(formdata);
        }

        temp.then((_rawData) => {
          tempData = SantralAnalysis.default.set_device_monthly_data(
            _rawData.data,
            measureList,
            self.chartText,
            self.chartTypeModel,
            self.reportTypeModel,
            deviceModelType,
            deviceList,
            dateList
          );
          this.setOpt(tempData);
        });
      }

      if (this.reportTypeModel === "Yearly") {
        if (this.analiysis_typeModel === "generalStats") {
          temp = getSantralYearly(formdata);
        }

        if (this.analiysis_typeModel === "INVERTER") {
          temp = inverterYearly(formdata);
        }

        if (this.analiysis_typeModel === "STRINGBOX") {
          temp = stringboxYearly(formdata);
        }

        temp.then((_rawData) => {
          tempData = SantralAnalysis.default.set_device_yearly_data(
            _rawData.data,
            measureList,
            self.chartText,
            self.chartTypeModel,
            self.reportTypeModel,
            deviceModelType,
            deviceList,
            self.yearList
          );
          this.setOpt(tempData);
        });
      }
    },
    setOpt(tempData) {
      // general-santral-analiz-daily
      const chartDom = document.getElementById("general-santral-analiz-daily");
      const myChart = echarts.init(chartDom);
      tempData.then((res) => {
        console.log(res);
        myChart.setOption(res.options, true);
        // this.chartData = res.options;
        this.desserts = res.desserts;
        this.componentKey += 1;
        this.show = true;
      });
    },

    selectedStatsParams() {
      this.finishDate = moment(this.startDate)
        .add(1, "day")
        .format("YYYY-MM-DD");
      const { reportTypeModel } = this;
      let params = {};

      if (reportTypeModel === "Yearly") {
        this.santralDataTable = `${this.yearList[0]}-santralAnalysisDataTable`;

        params = {
          condiniton: {
            date: this.yearList,
            measures: this.measuresModel,
            maxcount: 5000,
          },
        };
      }

      if (reportTypeModel === "Monthly") {
        const monthL = [];
        for (let i = 1; i < 13; i++) {
          monthL.push(i);
        }
        this.santralDataTable = `${this.yearModel}-santralAnalysisDataTable`;

        params = {
          condiniton: {
            date: [
              {
                year: this.yearModel,
                month: monthL,
              },
            ],
            measures: this.measuresModel,
            maxcount: 5000,
          },
        };
      }

      if (reportTypeModel === "Daily") {
        this.santralDataTable = `${this.yearModel} ${this.monthModel}-santralAnalysisDataTable`;

        params = {
          condiniton: {
            year: this.yearModel,
            month: this.monthModel,
            measures: this.measuresModel,
            maxcount: 5000,
          },
        };
      }

      if (reportTypeModel === "Hourly") {
        this.santralDataTable = `${this.startDate} - ${this.finishDate}-santralAnalysisDataTable`;

        params = {
          condiniton: {
            startDate: this.startDate,
            finishDate: this.finishDate,
            measures: this.measuresModel,
            maxcount: 5000,
          },
        };
      }

      if (reportTypeModel === "Minute") {
        this.santralDataTable = `${this.startDate} - ${this.finishDate}-santralAnalysisDataTable`;

        params = {
          condiniton: {
            startDate: this.startDate,
            finishDate: this.finishDate,
            santral: this.santralModel,
            measures: this.measuresModel,
            maxcount: 5000,
          },
        };
      }

      if (this.analiysis_typeModel === "generalStats") {
        params.condiniton.santral = this.santralModel;
      }

      if (this.analiysis_typeModel === "INVERTER") {
        params.condiniton.santral = this.santralModel_string;
        params.condiniton.INVERTER = this.inverterModel;
      }

      if (this.analiysis_typeModel === "STRINGBOX") {
        params.condiniton.santral = this.santralModel_string;
        params.condiniton.STRINGBOX = this.combinerBox;
      }

      return params;
    },

    /// / genel istatistik finish

    setStringMeasure() {
      this.measure_List = [];
      this.dcVoltageData = [];
      this.measuresModel = [];
      this.dcVoltageDataDisplay = false;

      // eslint-disable-next-line prefer-destructuring
      this.santralModel_string = this.santralDataList[0];
      const { stringboxMeasureList } = this.$store.getters;

      // eslint-disable-next-line prefer-destructuring
      this.measuresModel = stringboxMeasureList[0];
      this.measures = stringboxMeasureList;
    },

    setInverter() {
      this.chartData = {};
      this.inverterModel = [];
      // eslint-disable-next-line prefer-destructuring
      this.santralModel_string = this.santralDataList[0];
      const { inverterMeasureList } = this.$store.getters;

      // eslint-disable-next-line prefer-destructuring
      this.measuresModel = inverterMeasureList[0];
      this.measures = inverterMeasureList;
    },
    forceRerender() {
      this.renderComponent = false;
      this.$nextTick().then(() => {
        this.renderComponent = true;
      });
    },
  },
};
</script>

<style scoped>
.theme--dark
  .fullgridtable
  .theme--light.v-data-table
  tbody
  tr:not(:last-child) {
  border-bottom: none;
}

.fullgridtable .v-data-table th {
  border: thin solid #0000001f;
}

.fullgridtable .v-data-table td {
  border: thin solid #0000001f;
}

.fullgridtable.theme--dark .v-data-table th,
.fullgridtable.theme--dark .v-data-table td {
  border: thin solid hsla(0, 0%, 100%, 0.12);
}

.fullgridtable .theme--light.v-data-table tbody tr:not(:last-child) {
  border-bottom: none;
}

.fullgridtable.fullorder .v-data-table th,
.fullgridtable.fullorder .v-data-table td {
  border-bottom: 0 !important;
}

.fullgridtable.fullorder .v-data-table td:not(:last-child),
.fullgridtable.fullorder .v-data-table th:not(:last-child) {
  border-right: 0 !important;
}
</style>
